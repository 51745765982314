import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikSelect, MegProgramYear, useAlerts} from '@reasoncorp/kyber-js';

import {miSuiteReportApi} from '../../api';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {statewide4024ReportSchema} from '../../schema';

import * as messages from '../../messages';

type Props = {
  isOpen: boolean
  onGenerate: (year: number) => void
  onCancel: () => void
}

type FormFields = {
  year: number | null
}

const Statewide4024ReportModal = ({
                                    isOpen,
                                    onGenerate,
                                    onCancel
                                  }: Props) => {
  const {showErrorAlert} = useAlerts();
  const [megProgramYears, setMegProgramYears] = useState<MegProgramYear[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const findMegEqualizationYears = async () => {
      try {
        const megProgramYears = await miSuiteReportApi.findMegEqualizationYears();
        setMegProgramYears(megProgramYears.filter(megProgramYear => megProgramYear.year > 2024));
      } catch (e) {
        showErrorAlert(messages.UNABLE_TO_CONNECT_SERVICE);
        onCancel();
      }
      setIsLoading(false);
    };

    void findMegEqualizationYears();
  }, [
    onCancel,
    showErrorAlert
  ]);

  const initialValues: FormFields = useMemo(() => ({
    year: null
  }), []);

  const handleClose = useCallback((formikProps: FormikProps<FormFields>) => {
    formikProps.resetForm();
    onCancel();
  }, [
    onCancel
  ]);

  const renderOption = useMemo(() => (megProgramYear: MegProgramYear) => {
    return <option key={megProgramYear.year}
                   value={megProgramYear.year}>
      {megProgramYear.year - 1} for {megProgramYear.year}
    </option>;
  }, []);

  const handleSubmit = useCallback(async ({year}: FormFields, formikHelpers: FormikHelpers<FormFields>) => {
    if (year) {
      try {
        onGenerate(year);
        formikHelpers.resetForm();
      } finally {
        formikHelpers.setSubmitting(false);
      }
    }
  }, [
    onGenerate
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={statewide4024ReportSchema}
            enableReinitialize
            validateOnMount
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               size="lg"
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>
            Preliminary Statewide Recommended Equalized Values Report
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormikSelect name="year"
                              disabled={isLoading}
                              labelText="Equalization Cycle">
                  <option key="">Select</option>
                  {megProgramYears.map(renderOption)}
                </FormikSelect>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success"
                    className="mr-1"
                    onClick={formikProps.submitForm}
                    disabled={!formikProps.isValid || formikProps.isSubmitting || isLoading}>
              Generate
            </Button>
            <Button color="secondary"
                    onClick={() => handleClose(formikProps)}
                    disabled={formikProps.isSubmitting || isLoading}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};

export default Statewide4024ReportModal;