import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {escapeRegExp} from 'lodash';

import {
  Duration,
  DurationType,
  FormikSearchInput,
  ProgressIndicator,
  ProgressModal,
  ResultsLimiter,
  useAlerts,
  User
} from '@reasoncorp/kyber-js';

import {useSsoAppContext} from '../hooks';
import {reportApi, userApi} from '../api';
import * as messages from '../messages';
import {AddUserModal, UsersTable} from '../components';
import branding from '../branding';
import {AuditReportModal} from '../components/reports';

const Users = () => {
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {isAdmin, isCsr, isAuditor} = useSsoAppContext();
  const [userData, setUserData] = useState<{users: User[], filteredUsers: User[]}>({users: [], filteredUsers: []});
  const [lastSearchText, setLastSearchText] = useState('');
  const [resultLimit, setResultLimit] = useState<number | null>(50);
  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  const [auditReportModalIsOpen, setAuditReportModalIsOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false,
    exportingReport: false,
    progressModalTitle: ''
  });

  const showReports = useMemo(() => {
    return branding.name === 'PROSUITE';
  }, []);

  const getFilteredUsers = useCallback((users: User[], searchText: string) => {
    return users.filter(user => {
      if (!searchText) {
        return true;
      } else {
        const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');

        return searchTermRegexp.test(user.firstName) ||
          searchTermRegexp.test(user.lastName) ||
          searchTermRegexp.test(user.username);
      }
    });
  }, []);

  const handleAddUserClick = useCallback(() => {
    setAddUserModalIsOpen(true);
  }, []);

  const toggleNewUserModal = useCallback((shouldReopenOnSuccess: boolean, user?: User) => {
    if (user) {
      const users = userData.users.concat(user);
      const filteredUsers = getFilteredUsers(users, lastSearchText);
      setUserData({users, filteredUsers});
      setAddUserModalIsOpen(false);
      showSuccessAlert(messages.USER_ADD_SUCCESSFUL);

      if (shouldReopenOnSuccess) {
        setTimeout(() => setAddUserModalIsOpen(true), Duration.of(1, DurationType.SECONDS));
      }
    } else {
      setAddUserModalIsOpen(false);
    }
  }, [
    getFilteredUsers,
    lastSearchText,
    showSuccessAlert,
    userData.users
  ]);

  const handleAuditReportClick = useCallback(() => {
    setAuditReportModalIsOpen(true);
  }, []);

  const toggleAuditReportModal = useCallback((response?: Response) => {
    if (response) {
      setAuditReportModalIsOpen(false);
      showSuccessAlert(messages.REPORT_EXPORT_SUCCESSFUL);
    } else {
      setAuditReportModalIsOpen(false);
    }
  }, [
    showSuccessAlert
  ]);

  const handleUserReportClick = useCallback(async () => {
    try {
      setLoadingState(prevLoadingState => ({
        ...prevLoadingState,
        exportingReport: true,
        progressModalTitle: 'Generating User Report'
      }));
      await reportApi.downloadUserReport();
      showSuccessAlert(messages.REPORT_EXPORT_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_EXPORT_FAILED);
    } finally {
      setLoadingState(prevLoadingState => ({...prevLoadingState, exportingReport: false}));
    }
  }, [
    showSuccessAlert,
    showErrorAlert
  ]);

  const handleSearch = useCallback((searchText: string) => {
    setLastSearchText(searchText);
    setUserData({...userData, filteredUsers: getFilteredUsers(userData.users, searchText)});
  }, [
    getFilteredUsers,
    userData
  ]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const users = await userApi.users();
        setUserData({users, filteredUsers: [...users]});
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_RETRIEVE_USERS);
      }
    };
    void loadUsers();
  }, [
    showErrorAlert
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid>
        <Row className="mb-3">
          <Col className="d-flex justify-content-end">
            {isAuditor && showReports &&
              <Button className={!isAdmin && !isCsr ? '' : 'mr-2'}
                      color="primary"
                      onClick={handleAuditReportClick}
                      disabled={loadingState.loading || loadingState.loadError}>
                Audit Report
              </Button>
            }
            {isAdmin && showReports && <Button className="mr-2"
                                               color="primary"
                                               onClick={handleUserReportClick}
                                               disabled={loadingState.loading || loadingState.loadError}>
              User Report
            </Button>
            }
            {isAdmin && <Button color="primary"
                                onClick={handleAddUserClick}
                                disabled={loadingState.loading || loadingState.loadError}>
              Add User
            </Button>}
          </Col>
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <Card>
            <CardHeader className="bg-secondary text-uppercase text-white">Users</CardHeader>
            <CardBody>
              <FormikSearchInput onSubmit={handleSearch}
                                 disabled={loadingState.loading || loadingState.loadError}/>
              <UsersTable users={userData.filteredUsers}
                          readOnly={!isCsr && !isAdmin}
                          resultLimit={resultLimit}/>
            </CardBody>

          </Card>
          <ResultsLimiter message="Users to Show:"
                          recordName="User"
                          pluralRecordName="Users"
                          limitOptions={[
                            {displayValue: '50', value: 50},
                            {displayValue: '100', value: 100},
                            {displayValue: '250', value: 250},
                            {displayValue: 'All', value: null}
                          ]}
                          resultLimit={resultLimit}
                          totalRecords={userData.filteredUsers.length}
                          handleClick={(resultLimit) => setResultLimit(resultLimit)}/>
          {isAdmin &&
            <AddUserModal isOpen={addUserModalIsOpen}
                          onToggle={toggleNewUserModal}/>
          }
          {isAuditor && showReports &&
            <AuditReportModal isOpen={auditReportModalIsOpen}
                              onToggle={toggleAuditReportModal}/>
          }
          {showReports &&
            <ProgressModal isOpen={loadingState.exportingReport}
                           title={loadingState.progressModalTitle}
                           content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
          }
        </>
        }
      </Container>
    );
  }
};

export default Users;